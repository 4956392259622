@import 'colors';

$primary: (
  color: $material-color-red-500,
  dark:  $material-color-red-700,
  light: $material-color-red-100
);

$secondary: (
  color: $material-color-light-blue-a200,
  dark:  $material-color-light-blue-a400,
  light: $material-color-light-blue-a100
);

$theme-colors: (
  'primary': $primary
);

@import 'functions';
@import 'mixins';
@import 'variables';

$table-cell-padding-x: .5rem;
$table-cell-padding-y: .5rem;


// Base CSS

@import 'base/base';
@import 'base/grid';
@import 'base/typography';

// Bootstrap components
// Components covered in Bootstrap's css but not in Material design

@import 'bootstrap/alert';
@import 'bootstrap/badge';
@import 'bootstrap/breadcrumb';
@import 'bootstrap/carousel';
@import 'bootstrap/close';
@import 'bootstrap/code';
@import 'bootstrap/form';
@import 'bootstrap/image';
@import 'bootstrap/jumbotron';
@import 'bootstrap/media';
@import 'bootstrap/nav';
@import 'bootstrap/pagination';
@import 'bootstrap/popover';
@import 'bootstrap/responsive-embed';
@import 'bootstrap/transition';

// Material components
// Components covered in Material design (https://material.google.com/components)

@import 'material/button';
@import 'material/button-flat';
@import 'material/button-float';
@import 'material/button-group';
@import 'material/card';
@import 'material/chip';
@import 'material/data-table';
@import 'material/dialog';
@import 'material/expansion-panel';
@import 'material/menu';
@import 'material/navdrawer';
@import 'material/picker';
@import 'material/progress';
@import 'material/progress-circular';
@import 'material/selection-control';
@import 'material/slider';
@import 'material/snackbar';
@import 'material/stepper';
@import 'material/tab';
@import 'material/text-field';
@import 'material/text-field-box';
@import 'material/text-field-floating-label';
@import 'material/text-field-input-group';
@import 'material/toolbar';
@import 'material/tooltip';

// Utilities

@import 'utilities';

// Very basic print styles

@import 'print';
